import React from 'react';
import { Logo, Shapes } from '../common';
import './Gutter.scss';

const Gutter = () => (
  <aside className="layout-gutter">
    <div className="logo-container">
      <Logo clickable />
    </div>
    <div className="shapes-container">
      <Shapes />
    </div>
  </aside>
);

export default Gutter;
