import React, { useEffect, useRef } from 'react';
import './Plyr.scss';

const isClient = typeof window !== 'undefined';

const Plyr = (props) => {
  if (!isClient || !document) return null;
  const { videoUrl, videoProvider } = props;
  const playerRef = useRef();
  const plyrRef = useRef();

  useEffect(() => {
    if (isClient) {
      const plyr = require('plyr'); // eslint-disable-line
      plyrRef.current = plyr.setup('#plyr-player', { playsinline: true, debug: true });
    }
  }, []);

  return (
    <div
      id="plyr-player"
      data-plyr-provider={videoProvider.toLowerCase()}
      data-plyr-embed-id={videoUrl}
      ref={playerRef}
    />
  );
};

export default Plyr;
