import React from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

const LogoSvg = () => (
  <svg viewBox="0 0 274 141" stroke="black" strokeWidth="10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M73.7 135.9c18 0 32.7-14.6 32.7-32.7S91.8 70.6 73.7 70.6" />
    <path d="M73.7 70.4c18 0 32.7-14.6 32.7-32.7S91.8 5 73.7 5" />
    <path d="M138.4 135.9c36.1 0 65.5-29.3 65.5-65.5S174.6 4.9 138.4 4.9" />
    <path d="M203.1 103.2c0 18 14.6 32.7 32.7 32.7 18 0 32.7-14.6 32.7-32.7" />
    <path d="M73.7 70.4h-72" />
    <path d="M6.9 5C5.9 5 5 5.9 5 6.9V134c0 1 .8 1.9 1.9 1.9h259.8c1 0 1.9-.8 1.9-1.9V6.9c0-1-.8-1.9-1.9-1.9H6.9z" />
  </svg>
);

const Logo = (props) => {
  const {
    colour,
    size,
    clickable,
  } = props;
  const logoClasses = `logo${colour ? ` ${colour}` : ''}${size ? ` ${size}` : ''}`;
  return (
    clickable ? (
      <Link className={logoClasses} to="/" aria-label="Return Home">
        <LogoSvg />
      </Link>
    ) : (
      <div className={logoClasses}>
        <LogoSvg />
      </div>
    )
  );
};

export default Logo;
