import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { Image } from '.';
import './ProjectPreview.scss';

const truncate = (str, n) => {
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n - 1);
  return subString.substr(0, subString.lastIndexOf(' '));
};

const animateInValues = {
  ease: 'expo.out', yPercent: 0, opacity: 1, stagger: 0.16, duration: 1, visibility: 'visible',
};
const animateOutValues = {
  ease: 'expo.out', yPercent: 20, opacity: 0, duration: 0,
};

const ProjectPreview = (props) => {
  const {
    uid,
    client,
    projectSlug,
    projectName,
    featuredImage,
    openingDescription,
  } = props;

  const timelineRef = useRef();
  const textRef = useRef();
  const imageRef = useRef();

  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true });

  useEffect(() => {
    timelineRef.current = gsap.timeline();
    timelineRef.current.to(textRef.current.children, animateInValues, 0);
    // timelineRef.current.to(imageRef.current, animateInValues, 0);
    gsap.set(textRef.current.children, animateOutValues);
    // gsap.set(imageRef.current, animateOutValues);
  }, []);

  useEffect(() => {
    if (timelineRef.current) {
      if (inView) {
        timelineRef.current.play();
      } else {
        timelineRef.current.pause(0);
      }
    }
  }, [inView]);

  return (
    <div className="project-preview" ref={inViewRef}>
      <div className="cols">
        <div className="col col-left" ref={textRef}>
          <div className="project-title">
            <h1 className="project-client">{client.text}</h1>
            <span className="project-name">{projectName.text}</span>
          </div>
          <p className="project-description">{`${truncate(openingDescription.text, 150)}...`}</p>
          <Link
            className="more-button styled-button"
            to={`/${projectSlug}/${uid}/`}
            aria-label={`Read more about ${projectName.text}`}
          >
            <span>More</span>
            <svg className="arrow-icon" viewBox="0 0 196 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M163.702.321l31.878 23.377-31.523 27.627-.355-22.669H.066v-7.792h163.636V.321z" />
            </svg>
          </Link>
        </div>
        <div className="col col-right">
          <div className="featured-image" ref={imageRef}>
            <Image image={featuredImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPreview;
