import React from 'react';
import './Footer.scss';

const Gutter = ({ email }) => (
  <footer className="footer">
    <span className="name">Bryn Desmond Jones</span>
    <a href={`mailto:${email.text}`} className="email">{email.text}</a>
  </footer>
);

export default Gutter;
