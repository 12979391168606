import React from 'react';
import { HtmlContent, Form } from '../common';
import { getSlug } from '../../util/helpers';
import './EnquiryForm.scss';

const EnquiryForm = ({ input, location }) => {
  const { primary } = input;
  const { formTitle, formBody } = primary;
  const formAffix = getSlug(location);
  return (
    <section className="enquiry-form-block">
      <div className="cols">
        <div className="col col-top">
          <h1 className="title">{formTitle.text}</h1>
          <HtmlContent className="form-description" content={formBody.html} />
        </div>
        <div className="col col-bottom">
          <Form formName={`enquiry-form${formAffix ? `-${formAffix}` : ''}`} />
        </div>
      </div>
    </section>
  );
};

export default EnquiryForm;
