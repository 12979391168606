import React, { Component } from 'react';
import { Navigation } from '.';
import { HtmlContent, AnimatedLetterHead } from '../common';
import './Header.scss';

class Header extends Component {
  render() {
    const {
      location,
      navigation,
      shortBio,
      isHome,
    } = this.props;
    return (
      <header className="header">
        <div className="top">
          <AnimatedLetterHead isHome={isHome} />
        </div>
        <div className="bottom">
          <Navigation
            navigation={navigation}
            location={location}
          />
          <div className="bio">
            <HtmlContent content={shortBio.html} />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
