import React from 'react';
import { HtmlContent, Image } from '../common';
import './ProjectSection.scss';

const ProjectSection = ({ input }) => {
  const { primary, items: images } = input;
  const { sectionTitle, sectionDescription } = primary;
  return (
    <section className="project-section">
      <div className="cols">
        <div className="col col-left">
          <div className="section-description">
            <h2 className="title">{sectionTitle.text}</h2>
            <HtmlContent
              className="description"
              content={sectionDescription.html}
            />
          </div>
        </div>
        <div className="col col-right">
          <div className="opening-images">
            { images && images.map((imageData, index) => {
              const { image } = imageData;
              return (
                <div key={index} className="image-wrapper">
                  <Image image={image} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
