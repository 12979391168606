import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './Shapes.scss';

gsap.registerPlugin(ScrollTrigger);

const Shapes = () => {
  const containerRef = useRef();
  const svgRef = useRef();

  useEffect(() => {
    // Create parallax effect
    if (containerRef.current) {
      const tl = gsap.timeline();
      const scrollTrigger = ScrollTrigger.create({
        trigger: containerRef.current,
        pin: false,
        start: 'top top',
        end: 'bottom top',
        scrub: 1,
        animation: tl,
      });
      Array.from(svgRef.current.children).forEach((shape) => {
        const rateOfChange = Math.random();
        tl.to(shape, { y: `${rateOfChange * 2.5}%`, rotation: `${rateOfChange * 470}%`, transformOrigin: 'center center' }, 0);
      });
      // Determine rate of change based on zIndex
      // const rateOfChange = 25 * zIndex
      return () => {
        tl.kill();
        scrollTrigger.kill();
      };
    }
  }, [containerRef]);

  return (
    <div className="gutter-shapes" ref={containerRef}>
      <svg viewBox="0 0 48 775" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
        <path d="M43 92c-1.1-2.2-3.7-3.1-5.9-2-2.1 1-3 3.6-2.1 5.6.1.1.2.2.3.1L43 92z" fill="#80C342" />
        <path d="M15.2 331.4c2.2 2 5.6 1.9 7.6-.3 2-2.2 1.9-5.6 0-7.3-.2-.2-.4-.1-.6 0l-7 7.6z" fill="#ED2024" />
        <path d="M41.1 357.6c-2.2-2-5.6-1.9-7.6.3-2 2.2-1.9 5.6 0 7.3.2.2.4.1.6 0l7-7.6z" fill="#ED2024" />
        <path d="M26.9 211.5c2.2 2 5.6 1.9 7.6-.3 2-2.2 1.9-5.6 0-7.3-.2-.2-.4-.1-.6 0l-7 7.6z" fill="#ED2024" />
        <path d="M29.5 477.4c-2.2-2-5.6-1.9-7.6.3-2 2.2-1.9 5.6 0 7.3.2.2.4.1.6 0l7-7.6z" fill="#ED2024" />
        <path d="M21.3 129.9c2.2 2 5.6 1.9 7.6-.3 2-2.2 1.9-5.6 0-7.3-.2-.2-.4-.1-.6 0l-7 7.6z" fill="#ED2024" />
        <path d="M35.1 629.3c-2.2-2-5.6-1.9-7.6.3-2 2.2-1.9 5.6 0 7.3.2.2.4.1.6 0l7-7.6z" fill="#ED2024" />
        <path d="M18.2 70.6c-1.8-1.6-4.6-1.5-6.2.3-1.6 1.8-1.5 4.6.1 6 .1.1.3.1.4 0l5.7-6.3z" fill="#ED2024" />
        <path d="M35.5 50.3c-.1-.1-.3 0-.3.1l-3.7 7.4c-.1.1 0 .3.1.3l7.4 3.7c.1.1.3 0 .3-.1l3.7-7.4c.1-.1 0-.3-.1-.3l-7.4-3.7z" fill="#ECDC21" />
        <path d="M7 35.4c.1.1.3 0 .4-.1l4.6-6.9c.1-.1 0-.3-.1-.4L5 23.4c-.1-.1-.3 0-.4.1l-4.6 7c-.1.1 0 .3.1.4L7 35.4z" fill="#00BCDA" />
        <path d="M7 199.5c.1.1.3 0 .4-.1l4.6-6.9c.1-.1 0-.3-.1-.4L5 187.5c-.1-.1-.3 0-.4.1l-4.6 7c-.1.1 0 .3.1.4l6.9 4.5z" fill="#00BCDA" />
        <path d="M11.1 489.5c-.1-.1-.3 0-.4.1l-4.6 6.9c-.1.1 0 .3.1.4l6.9 4.6c.1.1.3 0 .4-.1l4.6-6.9c.1-.1 0-.3-.1-.4l-6.9-4.6z" fill="#00BCDA" />
        <path d="M20.1 54.4c-.1 0-.1.1-.1.2l1.2 3.9c0 .1.1.1.2.1l3.9-1.2c.1 0 .1-.1.1-.2l-1.2-3.9c0-.1-.1-.1-.2-.1l-3.9 1.2z" fill="#EF579F" />
        <path d="M16.8 95.1c-.1-.1-.2-.2-.3-.1L9 98.5c-.1.1-.2.2-.1.3l3.5 7.5c.1.1.2.2.3.1l7.5-3.5c.1-.1.2-.2.1-.3l-3.5-7.5z" fill="#3953A4" />
        <path d="M39.6 664.1c.1.1.2.2.3.1l7.5-3.5c.1-.1.2-.2.1-.3l-3.5-7.5c-.1-.1-.2-.2-.3-.1l-7.5 3.5c-.1.1-.2.2-.1.3l3.5 7.5z" fill="#3953A4" />
        <path d="M18.4 48.4c-2.1-4.3-7.4-6.2-11.7-4-4.3 2.1-6.2 7.4-4.3 11.3.1.3.4.4.7.2l15.3-7.5z" fill="#3953A4" />
        <path d="M15.8 273.7c-.2-.1-.5.1-.5.3l-2.4 8.5c-.1.2.1.5.3.5l8.5 2.4c.2.1.5-.1.5-.3l2.4-8.5c.1-.2-.1-.5-.3-.5l-8.5-2.4z" fill="#EF579F" />
        <path d="M40.5 415.3c.2.1.5-.1.5-.3l2.4-8.5c.1-.2-.1-.5-.3-.5l-8.5-2.4c-.2-.1-.5.1-.5.3l-2.4 8.5c-.1.2.1.5.3.5l8.5 2.4z" fill="#EF579F" />
        <path d="M4.7 154.5c-.2-.1-.5.1-.5.3l-2.4 8.5c-.1.2.1.5.3.5l8.5 2.4c.2.1.5-.1.5-.3l2.4-8.5c.1-.2-.1-.5-.3-.5l-8.5-2.4z" fill="#EF579F" />
        <path d="M43 564.9c.2.1.5-.1.5-.3l2.4-8.5c.1-.2-.1-.5-.3-.5l-8.5-2.4c-.2-.1-.5.1-.5.3l-2.4 8.5c-.1.2.1.5.3.5l8.5 2.4z" fill="#EF579F" />
        <path d="M31.6 5.8c-.4.1-.7.6-.6 1l2.3 7.7c.1.4.6.7 1 .6l7.7-2.3c.4-.1.7-.6.6-1l-2.3-7.7c-.1-.4-.6-.7-1-.6l-7.7 2.3z" fill="#3953A4" />
        <path d="M27.1 154.5c-.4.1-.7.6-.6 1l2.3 7.7c.1.4.6.7 1 .6l7.7-2.3c.4-.1.7-.6.6-1l-2.3-7.7c-.1-.4-.6-.7-1-.6l-7.7 2.3z" fill="#3953A4" />
        <path d="M29.2 604.7c.4-.1.7-.6.6-1l-2.3-7.7c-.1-.4-.6-.7-1-.6l-7.7 2.3c-.4.1-.7.6-.6 1l2.3 7.7c.1.4.6.7 1 .6l7.7-2.3z" fill="#3953A4" />
        <path d="M34.8 39.2c1.9-1.5 2.1-4.3.6-6.2-1.5-1.9-4.3-2.1-6-.8-.1.1-.1.2 0 .4l5.4 6.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M34.8 250.5c1.9-1.5 2.1-4.3.6-6.2-1.5-1.9-4.3-2.1-6-.8-.1.1-.1.2 0 .4l5.4 6.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M21.5 438.5c-1.9 1.5-2.1 4.3-.6 6.2 1.5 1.9 4.3 2.1 6 .8.1-.1.1-.2 0-.4l-5.4-6.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M21.4 334.3c2.2-1 3.1-3.7 2.1-5.8-1-2.2-3.7-3.1-5.6-2.2-.1.1-.2.2-.1.3l3.6 7.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M34.9 354.6c-2.2 1-3.1 3.7-2.1 5.8 1 2.2 3.7 3.1 5.6 2.2.1-.1.2-.2.1-.3l-3.6-7.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M33.1 214.5c2.2-1 3.1-3.7 2.1-5.8-1-2.2-3.7-3.1-5.6-2.2-.1.1-.2.2-.1.3l3.6 7.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M23.2 474.5c-2.2 1-3.1 3.7-2.1 5.8 1 2.2 3.7 3.1 5.6 2.2.1-.1.2-.2.1-.3l-3.6-7.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M7.8 4c1 2.2 3.6 3.1 5.8 2.1 2.2-1 3.1-3.6 2.2-5.6-.1-.1-.2-.2-.3-.1L7.8 4z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M21 128.5c1 2.2 3.6 3.1 5.8 2.1 2.2-1 3.1-3.6 2.2-5.6-.1-.1-.2-.2-.3-.1l-7.7 3.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M35.4 630.8c-1-2.2-3.6-3.1-5.8-2.1-2.2 1-3.1 3.6-2.2 5.6.1.1.2.2.3.1l7.7-3.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M42.6 92.4c-.2-2.4-2.3-4.2-4.7-4-2.4.2-4.2 2.3-4 4.5 0 .1.1.2.3.2l8.4-.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M18.9 68.1c-2-1.3-4.7-.8-6.1 1.2-1.3 2-.8 4.7 1 5.9.1.1.3 0 .4-.1l4.7-7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M34.3 48.9c-.1 0-.3.1-.3.2l-1.5 8.1c0 .1.1.3.2.3l8.1 1.5c.1 0 .3-.1.3-.2l1.5-8.1c0-.1-.1-.3-.2-.3l-8.1-1.5z" stroke="#000" strokeWidth="1" />
        <path d="M4.3 32.5c.1.1.2.2.3.1L12 29c.1-.1.2-.2.1-.3l-3.6-7.4c-.1-.1-.2-.2-.3-.1L.8 24.8c-.1.1-.2.2-.1.3l3.6 7.4z" stroke="#000" strokeWidth="1" />
        <path d="M4.3 196.5c.1.1.2.2.3.1L12 193c.1-.1.2-.2.1-.3l-3.6-7.4c-.1-.1-.2-.2-.3-.1l-7.4 3.6c-.1.1-.2.2-.1.3l3.6 7.4z" stroke="#000" strokeWidth="1" />
        <path d="M13.8 492.4c-.1-.1-.2-.2-.3-.1L6 495.9c-.1.1-.2.2-.1.3l3.6 7.4c.1.1.2.2.3.1l7.4-3.6c.1-.1.2-.2.1-.3l-3.5-7.4z" stroke="#000" strokeWidth="1" />
        <path d="M17.2 288.5c.1.1.2.2.3.1l7.4-3.6c.1-.1.2-.2.1-.3l-3.6-7.4c-.1-.1-.2-.2-.3-.1l-7.4 3.6c-.1.1-.2.2-.1.3l3.6 7.4z" stroke="#000" strokeWidth="1" />
        <path d="M39.2 400.5c-.1-.1-.2-.2-.3-.1l-7.4 3.6c-.1.1-.2.2-.1.3l3.6 7.4c.1.1.2.2.3.1l7.4-3.6c.1-.1.2-.2.1-.3l-3.6-7.4z" stroke="#000" strokeWidth="1" />
        <path d="M6 169.2c.1.1.2.2.3.1l7.4-3.6c.1-.1.2-.2.1-.3l-3.6-7.4c-.1-.1-.2-.2-.3-.1l-7.4 3.6c-.1.1-.2.2-.1.3l3.6 7.4z" stroke="#000" strokeWidth="1" />
        <path d="M41.7 550.1c-.1-.1-.2-.2-.3-.1l-7.4 3.6c-.1.1-.2.2-.1.3l3.6 7.4c.1.1.2.2.3.1l7.4-3.6c.1-.1.2-.2.1-.3l-3.6-7.4z" stroke="#000" strokeWidth="1" />
        <path d="M19.4 56.1c-.1 0-.1.1-.1.2l1.2 3.9c0 .1.1.1.2.1l3.9-1.2c.1 0 .1-.1.1-.2L23.5 55c0-.1-.1-.1-.2-.1l-3.9 1.2z" stroke="#000" strokeWidth="1" />
        <path d="M14.3 92.3c-.1-.1-.3-.1-.4.1l-4.7 6.8c-.1.1-.1.3.1.4l6.8 4.7c.1.1.3.1.4-.1l4.7-6.8c.1-.1.1-.3-.1-.4l-6.8-4.7z" stroke="#000" strokeWidth="1" />
        <path d="M42 667c.1.1.3.1.4-.1l4.7-6.8c.1-.1.1-.3-.1-.4l-6.8-4.7c-.1-.1-.3-.1-.4.1l-4.7 6.8c-.1.1-.1.3.1.4L42 667z" stroke="#000" strokeWidth="1" />
        <path d="M21.5 47.6c-1.1-4.7-5.9-7.6-10.6-6.5-4.7 1.1-7.6 5.9-6.6 10.1.1.3.3.4.6.4l16.6-4z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M29.6 713.3c-1.1-2.2-3.7-3.1-5.9-2s-3.1 3.7-2.1 5.6c.1.1.2.2.3.1l7.7-3.7z" fill="#80C342" />
        <path d="M30.6 772.8c2.2 2 5.6 1.9 7.6-.3 2-2.2 1.9-5.6 0-7.3-.2-.2-.4-.1-.6 0l-7 7.6z" fill="#ED2024" />
        <path d="M26.1 737.9c-.1-.1-.2-.2-.3-.1l-7.5 3.5c-.1.1-.2.2-.1.3l3.5 7.5c.1.1.2.2.3.1l7.5-3.5c.1-.1.2-.2.1-.3l-3.5-7.5z" fill="#3953A4" />
        <path d="M30.3 771.3c1 2.2 3.6 3.1 5.8 2.1 2.2-1 3.1-3.6 2.2-5.6-.1-.1-.2-.2-.3-.1l-7.7 3.6z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M29.1 713.7c-.2-2.4-2.3-4.2-4.7-4-2.4.2-4.2 2.3-4 4.5 0 .1.1.2.3.2l8.4-.7z" stroke="#000" strokeWidth="1" strokeLinecap="round" />
        <path d="M23.6 735.1c-.1-.1-.3-.1-.4.1l-4.7 6.8c-.1.1-.1.3.1.4l6.8 4.7c.1.1.3.1.4-.1l4.7-6.8c.1-.1.1-.3-.1-.4l-6.8-4.7z" stroke="#000" strokeWidth="1" />
      </svg>
    </div>
  );
};

export default Shapes;
