export const isClient = typeof window !== 'undefined';

// export const getSeededRandom = seedStr => seedRandom(seedStr)();
export const getNumberedAffix = index => `${(index < 9) ? `0${index + 1}` : index + 1}`;
export const toKebabCase = str => str.replace(/\s+/g, '-').toLowerCase();
export const getSlug = (location) => {
  if (!location) return null;
  const {
    pathname = '',
  } = location;
  // Remove all forward slashes to get just the slug
  return pathname ? pathname.replace(/\//g, '') : '';
};
export const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
export const phoneIsValid = (phoneNumber) => {
  const phoneExpression = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/; // eslint-disable-line
  return phoneExpression.test(phoneNumber);
};
