import React from 'react';
import './Form.scss';

export const Form = (props) => {
  const {
    formName,
  } = props;
  return (
    <form
      className="enquiry-form"
      name={formName}
      method="POST"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={formName} />
      <label htmlFor="bot-field" style={{ display: 'none' }}>
        <span>Don’t fill this out:</span>
        <input name="bot-field" type="text" id="name" placeholder="Don’t fill this out!" />
      </label>
      <label className="field half-width half-width-left" htmlFor="first-name">
        <span className="field-label">First name</span>
        <input
          type="text"
          id="first-name"
          name="first-name"
        />
      </label>
      <label className="field half-width half-width-right" htmlFor="last-name">
        <span className="field-label">Last name</span>
        <input
          type="text"
          id="last-name"
          name="last-name"
          required
        />
      </label>
      <label className="field half-width half-width-left" htmlFor="phone">
        <span className="field-label">Phone number</span>
        <input
          type="tel"
          pattern="^\+?\d{0,13}"
          id="phone"
          name="phone"
          required
        />
      </label>
      <label className="field half-width half-width-right" htmlFor="email">
        <span className="field-label">Email</span>
        <input
          type="email"
          id="email"
          name="email"
          required
        />
      </label>
      <label className="field full-width textarea-field" htmlFor="message">
        <span className="field-label">Message</span>
        <textarea id="message" name="message" rows="4" />
      </label>
      <div className="form-actions">
        <button
          className="button styled-button"
          type="submit"
          aria-label="Submit Form"
        >
          <span>Send</span>
        </button>
      </div>
    </form>
  );
};

export default Form;
