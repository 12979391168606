import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
import './AnimatedLetterHead.scss';

const AnimatedLetterHead = ({ isHome }) => {
  const logoRef = useRef();
  const [inViewRef, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const timelineRef = useRef();

  useEffect(() => {
    timelineRef.current = gsap.timeline();
    if (isHome) {
      timelineRef.current.to(logoRef.current.children, {
        yPercent: 0,
        opacity: 1,
        rotation: 0,
        stagger: 0.06,
        delay: 0.15,
        duration: 1.2,
        ease: 'expo.out',
      });
      gsap.set(logoRef.current.children, { yPercent: -100, opacity: 0, rotation: -10 });
    }
  }, []);
  useEffect(() => {
    if (timelineRef.current) {
      if (inView) {
        timelineRef.current.play();
      }
    }
  }, [inView]);
  return (
    <Link className="animated-letter-head" ref={inViewRef} to="/" aria-label="Return Home">
      <svg viewBox="0 0 918 87" fill="none" xmlns="http://www.w3.org/2000/svg" ref={logoRef}>
        <path d="M.6 68V.8h29.472c6.72 0 11.872 1.472 15.456 4.416C49.176 8.16 51 11.968 51 16.64c0 4.288-1.312 7.648-3.936 10.08-2.624 2.368-6.112 3.712-10.464 4.032v3.936c4.8.192 8.768 1.6 11.904 4.224 3.136 2.624 4.704 6.304 4.704 11.04 0 5.76-2.08 10.208-6.24 13.344C42.872 66.432 37.56 68 31.032 68H.6zm9.6-39.168h18.72c3.648 0 6.56-.896 8.736-2.688 2.24-1.792 3.36-4.224 3.36-7.296 0-3.008-1.056-5.28-3.168-6.816-2.112-1.6-5.088-2.4-8.928-2.4H10.2v19.2zm0 30.336h19.488c4.096 0 7.328-.992 9.696-2.976 2.432-1.984 3.648-4.64 3.648-7.968 0-3.392-1.184-6.016-3.552-7.872-2.368-1.92-5.632-2.88-9.792-2.88H10.2v21.696z" fill="#000" />
        <path d="M66.278 68V20.384h8.736c0 3.776-1.696 8.32-5.088 13.632h5.184c2.432-9.536 7.68-14.304 15.744-14.304 1.6 0 3.104.16 4.512.48v9.312c-2.048-.32-3.744-.48-5.088-.48-9.984 0-14.976 5.92-14.976 17.76V68h-9.024z" fill="#000" />
        <path d="M133.993 20.384h9.504L124.873 74.72c-1.664 4.608-3.552 7.744-5.664 9.408-2.048 1.728-5.184 2.592-9.408 2.592h-9.888v-8.064h9.6c1.792 0 3.168-.32 4.128-.96 1.024-.64 1.824-1.792 2.4-3.456l2.4-6.72-21.023-47.136h10.079L117.385 44c1.536 3.52 2.368 8.32 2.496 14.4h4.032c.064-4.928.736-9.376 2.016-13.344l8.064-24.672z" fill="#000" />
        <path d="M153.653 68V20.384h8.64c0 2.688-1.536 6.368-4.608 11.04h4.896c.96-3.2 2.784-6.048 5.472-8.544 2.688-2.56 5.952-3.84 9.792-3.84 5.504 0 9.632 1.568 12.384 4.704 2.816 3.072 4.224 7.456 4.224 13.152V68h-9.024V38.912c0-8-3.328-12-9.984-12-3.84 0-6.72 1.152-8.64 3.456a15.216 15.216 0 00-3.36 7.008c-.512 2.624-.768 5.984-.768 10.08V68h-9.024z" fill="#000" />
        <path d="M235.725 68V.8h23.616c9.6 0 17.376 3.072 23.328 9.216 6.016 6.144 9.024 14.176 9.024 24.096 0 10.496-2.976 18.784-8.928 24.864-5.888 6.016-13.888 9.024-24 9.024h-23.04zm9.6-8.832h12c16.192 0 24.288-8.32 24.288-24.96 0-7.68-2.016-13.696-6.048-18.048-4.032-4.352-9.888-6.528-17.568-6.528h-12.672v49.536z" fill="#000" />
        <path d="M301.039 44.384c0-7.616 2.016-13.728 6.048-18.336 4.032-4.672 9.216-7.008 15.552-7.008s11.456 2.208 15.36 6.624c3.904 4.416 5.856 10.048 5.856 16.896 0 1.216-.096 2.528-.288 3.936h-33.504c.192 5.12 1.472 8.96 3.84 11.52 2.432 2.496 5.536 3.744 9.312 3.744 5.696 0 9.856-3.04 12.48-9.12l8.256 2.976c-1.664 4.416-4.32 7.808-7.968 10.176-3.584 2.368-7.904 3.552-12.96 3.552-6.72 0-12.064-2.176-16.032-6.528-3.968-4.416-5.952-10.56-5.952-18.432zm9.216-5.088h24.192c-.064-4.032-1.216-7.2-3.456-9.504-2.24-2.368-5.024-3.552-8.352-3.552-3.264 0-6.048 1.184-8.352 3.552-2.304 2.368-3.648 5.536-4.032 9.504z" fill="#000" />
        <path d="M351.735 55.712l8.544-2.784c1.6 6.016 5.76 9.024 12.48 9.024 6.784 0 10.176-2.208 10.176-6.624 0-3.52-2.848-5.856-8.544-7.008l-5.76-1.056c-4.928-1.024-8.672-2.528-11.232-4.512-2.496-2.048-3.744-5.12-3.744-9.216 0-4.224 1.728-7.68 5.184-10.368 3.456-2.752 7.712-4.128 12.768-4.128 5.184 0 9.408 1.12 12.672 3.36 3.328 2.176 5.568 5.088 6.72 8.736l-7.968 3.072c-1.92-5.376-5.76-8.064-11.52-8.064-2.944 0-5.216.64-6.816 1.92-1.6 1.216-2.4 2.72-2.4 4.512 0 1.664.544 2.944 1.632 3.84 1.088.896 3.232 1.664 6.432 2.304l5.664 1.152c4.096.768 7.808 2.272 11.136 4.512 3.328 2.176 4.992 5.568 4.992 10.176 0 4.544-1.728 8.16-5.184 10.848-3.456 2.624-8.224 3.936-14.304 3.936-5.248 0-9.824-1.216-13.728-3.648-3.84-2.432-6.24-5.76-7.2-9.984z" fill="#000" />
        <path d="M403.966 68V20.384h8.352c0 2.88-1.216 6.112-3.648 9.696h4.8c2.304-7.36 6.72-11.04 13.248-11.04 3.328 0 6.176.992 8.544 2.976 2.432 1.92 4.096 4.608 4.992 8.064h3.84c.704-2.944 2.336-5.504 4.896-7.68 2.56-2.24 5.568-3.36 9.024-3.36 4.928 0 8.864 1.6 11.808 4.8 2.944 3.136 4.416 7.424 4.416 12.864V68h-9.024V38.912c0-7.936-3.328-11.904-9.984-11.904-3.456 0-6.048 1.024-7.776 3.072-1.6 1.792-2.656 3.904-3.168 6.336-.512 2.368-.768 5.408-.768 9.12V68h-8.832V38.912c0-7.936-3.36-11.904-10.08-11.904-3.392 0-5.952 1.024-7.68 3.072-2.624 3.008-3.936 8.16-3.936 15.456V68h-9.024z" fill="#000" />
        <path d="M492.156 62.144c-4.352-4.8-6.528-10.784-6.528-17.952 0-7.168 2.144-13.152 6.432-17.952 4.288-4.8 9.696-7.2 16.224-7.2 6.528 0 11.904 2.4 16.128 7.2 4.288 4.8 6.432 10.784 6.432 17.952 0 7.168-2.144 13.152-6.432 17.952-4.288 4.8-9.664 7.2-16.128 7.2-6.4 0-11.776-2.4-16.128-7.2zm29.376-17.76c0-5.696-1.216-10.048-3.648-13.056-2.368-3.008-5.568-4.512-9.6-4.512-4.096 0-7.328 1.504-9.696 4.512-2.368 3.008-3.552 7.36-3.552 13.056 0 5.568 1.152 9.824 3.456 12.768 2.368 2.944 5.632 4.416 9.792 4.416 4.096 0 7.328-1.472 9.696-4.416 2.368-3.008 3.552-7.264 3.552-12.768z" fill="#000" />
        <path d="M542.903 68V20.384h8.64c0 2.688-1.536 6.368-4.608 11.04h4.896c.96-3.2 2.784-6.048 5.472-8.544 2.688-2.56 5.952-3.84 9.792-3.84 5.504 0 9.632 1.568 12.384 4.704 2.816 3.072 4.224 7.456 4.224 13.152V68h-9.024V38.912c0-8-3.328-12-9.984-12-3.84 0-6.72 1.152-8.64 3.456a15.216 15.216 0 00-3.36 7.008c-.512 2.624-.768 5.984-.768 10.08V68h-9.024z" fill="#000" />
        <path d="M595.226 44.096c0-7.488 1.824-13.536 5.472-18.144 3.648-4.608 8.288-6.912 13.92-6.912 7.552 0 12.672 3.872 15.36 11.616h5.28c-3.52-4.928-5.28-11.2-5.28-18.816V.8h9.024V68h-8.16c0-2.816 1.44-6.24 4.32-10.272h-5.088c-1.472 3.52-3.36 6.336-5.664 8.448-2.24 2.112-5.504 3.168-9.792 3.168-5.632 0-10.272-2.304-13.92-6.912-3.648-4.608-5.472-10.72-5.472-18.336zm31.584 13.056c2.432-3.072 3.648-7.424 3.648-13.056 0-5.632-1.184-9.92-3.552-12.864-2.368-3.008-5.568-4.512-9.6-4.512-8.448 0-12.672 5.792-12.672 17.376 0 11.712 4.224 17.568 12.672 17.568 3.968 0 7.136-1.504 9.504-4.512z" fill="#000" />
        <path d="M666.509 47.744l9.888-2.4c0 5.248.736 9.152 2.208 11.712 1.472 2.496 3.84 3.744 7.104 3.744 3.264 0 5.632-1.184 7.104-3.552 1.536-2.432 2.304-6.176 2.304-11.232V.8h9.6v45.504c0 15.36-6.304 23.04-18.912 23.04-6.08 0-10.784-1.888-14.112-5.664-3.328-3.84-5.056-9.152-5.184-15.936z" fill="#000" />
        <path d="M722.875 62.144c-4.352-4.8-6.528-10.784-6.528-17.952 0-7.168 2.144-13.152 6.432-17.952 4.288-4.8 9.696-7.2 16.224-7.2 6.528 0 11.904 2.4 16.128 7.2 4.288 4.8 6.432 10.784 6.432 17.952 0 7.168-2.144 13.152-6.432 17.952-4.288 4.8-9.664 7.2-16.128 7.2-6.4 0-11.776-2.4-16.128-7.2zm29.376-17.76c0-5.696-1.216-10.048-3.648-13.056-2.368-3.008-5.568-4.512-9.6-4.512-4.096 0-7.328 1.504-9.696 4.512-2.368 3.008-3.552 7.36-3.552 13.056 0 5.568 1.152 9.824 3.456 12.768 2.368 2.944 5.632 4.416 9.792 4.416 4.096 0 7.328-1.472 9.696-4.416 2.368-3.008 3.552-7.264 3.552-12.768z" fill="#000" />
        <path d="M773.622 68V20.384h8.64c0 2.688-1.536 6.368-4.608 11.04h4.896c.96-3.2 2.784-6.048 5.472-8.544 2.688-2.56 5.952-3.84 9.792-3.84 5.504 0 9.632 1.568 12.384 4.704 2.816 3.072 4.224 7.456 4.224 13.152V68h-9.024V38.912c0-8-3.328-12-9.984-12-3.84 0-6.72 1.152-8.64 3.456a15.216 15.216 0 00-3.36 7.008c-.512 2.624-.768 5.984-.768 10.08V68h-9.024z" fill="#000" />
        <path d="M825.945 44.384c0-7.616 2.016-13.728 6.048-18.336 4.032-4.672 9.216-7.008 15.552-7.008s11.456 2.208 15.36 6.624c3.904 4.416 5.856 10.048 5.856 16.896 0 1.216-.096 2.528-.288 3.936h-33.504c.192 5.12 1.472 8.96 3.84 11.52 2.432 2.496 5.536 3.744 9.312 3.744 5.696 0 9.856-3.04 12.48-9.12l8.256 2.976c-1.664 4.416-4.32 7.808-7.968 10.176-3.584 2.368-7.904 3.552-12.96 3.552-6.72 0-12.064-2.176-16.032-6.528-3.968-4.416-5.952-10.56-5.952-18.432zm9.216-5.088h24.192c-.064-4.032-1.216-7.2-3.456-9.504-2.24-2.368-5.024-3.552-8.352-3.552-3.264 0-6.048 1.184-8.352 3.552-2.304 2.368-3.648 5.536-4.032 9.504z" fill="#000" />
        <path d="M876.641 55.712l8.544-2.784c1.6 6.016 5.76 9.024 12.48 9.024 6.784 0 10.176-2.208 10.176-6.624 0-3.52-2.848-5.856-8.544-7.008l-5.76-1.056c-4.928-1.024-8.672-2.528-11.232-4.512-2.496-2.048-3.744-5.12-3.744-9.216 0-4.224 1.728-7.68 5.184-10.368 3.456-2.752 7.712-4.128 12.768-4.128 5.184 0 9.408 1.12 12.672 3.36 3.328 2.176 5.568 5.088 6.72 8.736l-7.968 3.072c-1.92-5.376-5.76-8.064-11.52-8.064-2.944 0-5.216.64-6.816 1.92-1.6 1.216-2.4 2.72-2.4 4.512 0 1.664.544 2.944 1.632 3.84 1.088.896 3.232 1.664 6.432 2.304l5.664 1.152c4.096.768 7.808 2.272 11.136 4.512 3.328 2.176 4.992 5.568 4.992 10.176 0 4.544-1.728 8.16-5.184 10.848-3.456 2.624-8.224 3.936-14.304 3.936-5.248 0-9.824-1.216-13.728-3.648-3.84-2.432-6.24-5.76-7.2-9.984z" fill="#000" />
      </svg>
    </Link>
  );
};

export default AnimatedLetterHead;
