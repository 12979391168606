import React from 'react';
import './HtmlContent.scss';

const HtmlContent = (props) => {
  const {
    className,
    content,
    themeClass,
  } = props;
  const classes = `${className ? ` ${className}` : ''}${themeClass ? ` ${themeClass}` : ''}`;
  return (
    <div
      className={`html-content${classes}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default HtmlContent;
