import React from 'react';
import { ProjectSection, EnquiryForm } from './Slices';

const PROJECT_SECTION_TYPE = 'project_section';
const ENQUIRY_FORM_TYPE = 'enquiry_form';

const SliceZone = (props) => {
  const { allSlices, location } = props;
  const slices = allSlices.map((s) => {
    switch (s.sliceType) {
      // These are the API IDs of the slices
      case PROJECT_SECTION_TYPE:
        return <ProjectSection key={s.id} input={s} location={location} />;
      case ENQUIRY_FORM_TYPE:
        return <EnquiryForm key={s.id} input={s} location={location} />;
      default:
        return null;
    }
  });
  return (
    <div className="slice-zone">
      {slices}
    </div>
  );
};

export default SliceZone;
